import React from 'react';
import Push from 'push.js';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect } from 'react-redux-firebase';
import Swal from 'sweetalert2';
import Bowser from 'bowser';

import { emitToSocket, getUser, tokenManagment } from '../helpers';
import { getMenu, getMyId, getTodayOrdersStatus, getBurgerHistory } from '../store/selectors';
import Loading from './Loading';
import { getCrazyName } from './Orders';

const burgers = {
    jankes: 'Jankes',
    beef: 'BEE(F)',
    straznik: 'Strażnik Texasu',
    elmariachi: 'El Mariachi',
    lesny: 'Leśny',
    bluemike: 'Blue Mike',
    jerry: 'Jerry',
    bazyli: 'Bazyli',
    alemeksyk: 'Ale meksyk',
    aloha: 'Aloha',
    zorba: 'Zorba',
    mandzur: 'Mandżur',
    drpepper: 'Dr. Pepper',
    elvis: 'Elvis',
    mocarello: 'Mocarello',
    wiki: 'Wiki',
    gringo: 'Gringo',
    ludomil: 'Ludomił',
    diego: 'Diego',
    weggington: 'Weggington',
    halloween: 'Halloween burger',
    wegierka: 'Węgierka',
    redneck: 'Redneck',
    'major-tom': 'Major Tom',
    fatboy: 'Fatboy',
    kimchirimczi: 'Kimchi Rimczi',
    italiano: 'Italiano',
    special: 'Special burger (details on slack)',
    'pan-miyagi': 'Pan Miyagi',
    hempeat: 'Hempeat',
    dzemboy: 'Dżemboy',
    'juwe-cobb-burger': 'Juwe Cobb Burger',
    'dolce-vita': 'Dolce Vita',
    'pulled-tex-mex': 'Pulled Tex Mex',
    'pulled-beef': 'Pulled Beef',
    'pastrami-sandwich': 'Pastrami sandwich',
    'smash-burger': 'Smash burger',
    'smash-burger-2': 'Smash burger 2.0',
    partyzant: 'Partyzant',
    friesWithCheeseSauce: 'Fries with cheese sauce, bacon and chives',
    'ostry-kurczak-koreanski': 'O.K.K - Ostry Kurczak Koreański',
    fratelli: 'Fratelli',
    'pulled-pork': 'Pulled Pork',
    tom: 'Tom',
};

const ingredientsNames = {
    beef: '🥩 Beef',
    hamburgersauce: '🍔 Hamburger sauce',
    lettuce: '🥬 Lettuce',
    cheese: '🧀 Cheese',
    redonion: '🧅 Red onion',
    pickle: '🥒 Pickle',
    ogorek: '🥒 Pickle',
    cucumber: '🥒 Cucumber',
    panierowany_ser: '🧀 Breaded cheese',
    tomato: '🍅 Tomato',
    freshtomato: '🍅 Tomato',
    mayo: '🍼 Mayo',
    rucola: '🥬 Rucola',
    honerymutardsauce: '🍯 Honey mustard sauce',
    caramelizedonion: '🧅 Caramelized onion',
    grilledbacon: '🥓 Grilled bacon',
    bbqsauce: '🍖 BBQ Sauce',
    jalapeno: '🌶️ Jalapeño',
    spicysalsasauce: '💃 Spicy Salsa sauce',
    camembert: '🧀 Camembert',
    grilledmuschrooms: '🍄 Grilled muschrooms',
    bluecheese: '🧀 Blue cheese',
    marmaladeofcranberry: '🍓 Marmalade of cranberry',
    cheesesauce: '🧀 Cheese sauce',
    nachos: '🥨 Nachos',
    mozarella: '🧀 Mozarella',
    basilsauce: '🌿 Basil sauce',
    sundriedtomatoes: '🍅 Sundried tomatoes',
    bulka_zytnia: '🐎 Rye loaf',
    podwojnyboczek: '🥓🥓 Double Bacon',
    boczek: '🥓 Bacon',
    banana: '🍌 Banana',
    peanutbutter: '🥜 Peanut butter',
    baconsauce: '🥓 Bacon sauce',
    onionrings: '🧅 Onion rings',
    cheddarcheesesauce: '🧀 Cheedar cheese sauce',
    ketchup: '🍅 Ketchup',
    smokedcheese: '🧀 Smoked cheese',
    grilledsausage: '🌭 Grilled sausage',
    sauerkraut: '🥬 Sauerkraut',
    mustard: '🍯 Mustard',
    kotlet: '🥩 Beef',
    ser: '🧀 Cheese',
    pieczarki: '🍄 Grilled muschrooms',
    yoghurtsauce: '🥛 Yoghurt sauce',
    onion: '🧅 Onion',
    grilledchorizo: '🌭 Grilled chorizo',
    hotchipottlepeppersinsauce: '🌶️ Hot Chipottle peppers in sauce',
    pineapple: '🍍 Grilled pineapple',
    dutchsauce: '🇩🇰 Dutch sauce',
    mintyoghurtsauce: '🌿 Mint-yoghurt sauce',
    brioche: '🧈 Brioche',
    feta: '🧀 Feta',
    'tzatziki-sauce': '🥒 Tzatziki sauce',
    'mango-habanero-sauce': '🥭🌶️ Mango-habanero sauce',
    'mimolette-cheese': '🧀 Mimolette cheese',
    'garlic-sauce': '🧄 Garlic sauce',
    'salami-pepperoni': '🍖 Salami Pepperoni',
    'pepperoni-pepper': '🌶️ Pepperoni pepper',
    'mozarella-sticks': '🧀 Breaded Mozarella sticks',
    'mayo-jalapeno-sauce': '🌶️ Mayo-jalapeno sauce',
    kiwi: '🥝 Kiwi',
    'cheddar-cheese-sauce': '🧀 Cheddar cheese sauce',
    'onion-rings': '🧅 Onion rings',
    sweetpotatofries: '🍟 Sweet potato fries',
    creamsaucewithjalapeno: '🌶️ Cream sauce with jalapeno',
    'chimichurri-sauce': '🌿 Chimichurri sauce',
    batatyfries: '🍟 Sweet potatoes fries',
    cheeseChivesFries: '🍟 Fries with 🧀 cheese sauce, 🥓 grilled bacon and 🧅 chives',
    'pumpkin-chutney': '🎃 Pumpkin chutney',
    'carolina-reaper': '🌶️🌶️🌶️🌶️ Carolina reapers (hot peppers) 🌶️🌶️🌶️🌶️',
    challach: '🥐 Challach',
    'plum-jam': '🍯 Plum jam',
    'lambs-lettuce': "🐑 Lamb's lettuce",
    sunflower: '🌻 Sunflower',
    'goat-cheese': '🐐 Goat cheese',
    'small-amount-of-bbq': '🍖 Small amount of bbq',
    'breaded-cheese': '🧀 Breaded cheese',
    'roasted-onion': '🧅 Roasted onion',
    'mayo-sriracha-sauce': '🌶️ Mayo-sriracha sauce',
    'mango-jalapeno-sauce': '🥭 Mango-jalapeno sauce',
    'camembert-bites': '🧀 Camembert bites',
    'grilled-white-sausage': '🌭 Grilled white sausage',
    'onion-fried-in-butter': '🧅 Onion fried in butter',
    'horseradish-sauce': '🐴 Horseradish sauce',
    kimchi: '🇰🇷🍯🌿 Kimchi',
    'roasted-peanuts': '🥜 Roasted peanuts',
    'korean-bbq-sauce': '🇰🇷 Korean bbq sauce',
    'tomato-sauce': '🍅 Tomato sauce',
    'salame-napoli': '🐷 Salame napoli',
    parmesan: '🧀 Parmesan',
    'basil-pesto': '🌿 Basil pesto',
    pork: '🐷 Pork',
    'chilli-peppers': '🌶️ Chilli peppers',
    'bacon-marinated-in-soy-sauce': '🥓 Bacon marinated in soy sauce',
    'teryaki-sauce': '🥡 Teryaki sauce',
    hemp: '🌿 Hemp',
    cheese2x: '🧀 2x cheese',
    'butter-loaf': '🧈 Butter loaf',
    bulka_maslana: '🧈 Butter loaf',
    'butter-load': '🧈 Butter loaf',
    'whiskey-bbq-sauce': '🥃 Whiskey bbq sauce',
    'bacon-jam': '🥓 Bacon jam',
    'chicken-strips': '🐔 Chicken strips',
    'red-onion': '🧅 Red onion',
    '2xcheese': '🧀 2x cheese',
    'grilled-pineapple': '🍍 Grilled pineapple',
    mascarpone: '🧀 Mascarpone',
    'truffle-mayo': '🍼 Truffle mayonnaise',
    'salami-spianata-piccante': '🍖 Salami Spianata Piccante',
    'pulled-pork': '🐽 Pulled pork',
    'jalapeno-sauce': '🌶️ Jalapeno sauce',
    'ranch-sauce': '🍼 Ranch sauce',
    pastrami: '🥩 Pastrami',
    'butter-baguette': '🍞 Butter baguette',
    'non-russian-sauce': '🚫🇷🇺🍼 Non-russian sauce',
    '2xcheddar': '🧀🧀 2x Cheddar',
    '2x90beefsmash': '🥩👊🥩👊 2x 90g Beef in SMASH style',
    'grilled-onion': '🧅 Grilled onion',
    pickles: '🥒 Pickled cucumber',
    'aioli-sauce': '🧄 Aioli sauce',
    tomatoes: '🍅 Tomatoes',
    pomegranate: '🍎 Pomegranate',
    'balsamic-sauce': '🫗 Balsamic sauce',
    chives: '🧅 Chives',
    fried: '🍟 Fries',
    fries: '🍟 Fries',
    'breaded-chicken-tenderloin-with-spicy-korean-glaze':
        '🐔🇰🇷 Breaded chicken tenderloin with spicy korean glaze',
    'butter-load-with-sesame-seeds': '🍞𓇢 Butter loaf with sesame seeds',
    'grilled-bacon': '🥓 Grilled bacon',
    sesame: '𓇢 Sesame',
    straciatella: '🧀 Straciatella',
    'spicy-sasuage-nduja': "🍖 Spicy sausage 'Nduja",
    'spicy-peppers-peperoncini': '🌶️Spicy peppers Peperoncini',
    olives: '🫒 Olives',
    'spicy-bbq-sauce': '🍼 Spicy BBQ sauce',
    'mayo-chipotle-sauce': '🫙 Majo-Chipotle sauce',
    cheddar: '🧀 Cheddar cheese',
    'hot-cheese-sauce': '🧀♨️ Hot cheese sauce',
};

const track = (name) => {
    if (window.tidioChatApi && typeof window.tidioChatApi.track === 'function') {
        window.tidioChatApi.track(name);
    }
};

const additionals = {
    halffries: '🍟 Half of fries',
    fries: '🍟 Fries',
    halfbatatyfries: '🍟 Half of sweet potatoes fries',
    batatyfries: '🍟 Sweet potatoes fries',
    halfCheeseChivesFries: '🍟 Half of fries with 🧀 cheese sauce, 🥓 grilled bacon and 🧅 chives',
    cheeseChivesFries: '🍟 Fries with 🧀 cheese sauce, 🥓 grilled bacon and 🧅 chives',
    cola: '🥤 Coca cola',
    colazero: '🥤 Coca cola zero',
};
//
const getBurgerName = (burger) => {
    if (!burger) {
        return '';
    }
    if (!burgers[burger.burger]) {
        return burger.burger;
    }
    return burgers[burger.burger];
};

const getMeatName = (meat) => {
    if (!meat) {
        return '';
    }
    if (meat === 'normal') {
        return '🥩 Beef';
    }
    if (meat === 'cheese') {
        return '🧀 Breaded cheese';
    }
    if (meat === 'hempeat') {
        return '🌿 Hempeat';
    }
    if (meat === 'no-meat') {
        return '😔 No meat';
    }
};

const getLoafName = (loaf) => {
    if (!loaf) {
        return '';
    }
    if (loaf === 'bulka_maslana') {
        return '🧈 Butter loaf';
    }
    if (loaf === 'bulka_zytnia') {
        return '🐎 Rye loaf';
    }
    if (loaf === 'regular-loaf') {
        return '🍞 Regular loaf';
    }
};

const getIngredientName = (ingredient) => {
    if (!ingredient) {
        return '';
    }
    if (!ingredientsNames[ingredient.name]) {
        return ingredient.name;
    }
    return ingredientsNames[ingredient.name];
};

const getAdditionalName = (additional) => {
    if (!additional) {
        return '';
    }
    if (!additionals[additional.name]) {
        return additional.name;
    }
    return additionals[additional.name];
};

const meats = ['normal', 'cheese', 'hempeat', 'no-meat'];
const loafs = ['regular-loaf', 'bulka_zytnia', 'bulka_maslana'];

const initialState = {
    place: 'takeaway',
    burger: null,
    meat: 'normal',
    loaf: 'regular-loaf',
    ingredients: {},
    additional: {},
    user: '',
    uwagi: '',
    dateStart: new Date().getTime(),
};

const banned = {
    MSqHaMdHiteTyVvhvgKiSXYFHOk1: 'MNIEJSZE MIESO!!1ONEONE1!!! GCC LEPSZE!!!11111',
    u1DQnUAq5VeCyC2wQ4QbbeUKb593: 'Jedz pasikuta z Oskarem',
};

const takeAway = [
    'ENI9VH0W08gznkRqBt1BCs61KR72',
    'G4caRXUVrghYEF9uNChJ7zwhvZP2',
    'Ux4w5HXuYGSYzhVu6ql54NADx1y1',
    'WKjkGYsmDKUID9t5qQGzwuwv1lq1', // kwiatkowski jebac go
    'Wb9q88rm1ZeJxtYqD47yuuivuIp1',
    'dmLz4cMgXreyFno1BkeVTl3ke9h1',
    'fld3jkYBTNO1C8XWXOooFBbCAxH2',
    'o8gY8ZRtBefQnW9RfHDKs8HIVbd2',
    'qPXSXGWEL6eWXNTheiNw2W1BUXR2',
    'rSwKY6x0WJc9m4n0aQHr4aOmzhx2',
    'weMZ8Vcl4VdEuofOxsIbvQX83th2',
];

const theUsual = {
    Wb9q88rm1ZeJxtYqD47yuuivuIp1: {
        // jarek
        burger: 'straznik',
        place: 'bro',
        ingredients: {},
        additional: {
            colazero: true,
            halffries: true,
        },
    },
    fld3jkYBTNO1C8XWXOooFBbCAxH2: {
        // marcin g
        burger: 'straznik',
        place: 'bro',
        ingredients: {},
        additional: {
            colazero: true,
            halffries: true,
        },
    },
    Ux4w5HXuYGSYzhVu6ql54NADx1y1: {
        // grzegorz
        burger: 'jerry',
        place: 'bro',
        ingredients: {},
        additional: {
            halffries: true,
        },
    },
    WKjkGYsmDKUID9t5qQGzwuwv1lq1: {
        // MANY
        burger: 'bluemike',
        place: 'bro',
        ingredients: {},
        additional: {},
    },
    qPXSXGWEL6eWXNTheiNw2W1BUXR2: {
        // krzysio
        burger: 'straznik',
        place: 'bro',
        ingredients: {},
        additional: {
            halffries: true,
            colazero: true,
        },
    },
    Bt4VPRzJsZSeenrWdmILhQco7Fi1: {
        // pawel
        burger: 'jankes',
        place: 'takeaway',
        ingredients: {
            podwojnyboczek: true,
        },
        additional: {},
    },
    weMZ8Vcl4VdEuofOxsIbvQX83th2: {
        // kamil k
        burger: 'jankes',
        place: 'bro',
        ingredients: {},
        additional: {
            halffries: true,
        },
    },
    ENI9VH0W08gznkRqBt1BCs61KR72: {
        // ewa
        burger: 'jankes',
        place: 'bro',
        ingredients: {},
        additional: {},
    },
    bTEQ2F1ssZWuIEfLW4KFGxK8nqn2: {
        // marcmas
        burger: 'beef',
        place: 'takeaway',
        ingredients: {
            boczek: true,
        },
        additional: {},
    },
    UZR56Lytf7VvjKi0DsHZyAPCcus1: {
        // bpolak
        burger: 'straznik',
        place: 'takeaway',
        ingredients: {
            podwojnyboczek: true,
        },
        additional: {
            fries: true,
        },
    },
};

const Burgers = (props) => {
    function reducer(state, action) {
        switch (action.type) {
            case 'BURGER-SET-PLACE': {
                return {
                    ...state,
                    place: action.place,
                };
            }
            case 'BURGER-SET-BURGER':
                const loaf = props.menu.burgers[action.burger].ingredients.find((ing) =>
                    ['regular-loaf', 'bulka_maslana', 'bulka_zytnia'].includes(ing),
                );
                return {
                    ...state,
                    burger: action.burger,
                    loaf,
                };
            case 'BURGER-NO-BURGER':
                return {
                    ...state,
                    burger: false,
                };
            case 'BURGER-TOGGLE-INGREDIENT': {
                const ingredients = { ...state.ingredients };
                if (ingredients[action.ingredient]) {
                    delete ingredients[action.ingredient];
                } else if (action.ingredient === 'boczek' && ingredients.podwojnyboczek) {
                    delete ingredients.podwojnyboczek;
                    ingredients[action.ingredient] = true;
                } else if (action.ingredient === 'podwojnyboczek' && ingredients.boczek) {
                    delete ingredients.boczek;
                    ingredients[action.ingredient] = true;
                } else {
                    ingredients[action.ingredient] = true;
                }
                return { ...state, ingredients };
            }

            case 'BURGER-TOGGLE-MEAT': {
                return { ...state, meat: action.meat };
            }
            case 'BURGER-TOGGLE-LOAF': {
                return { ...state, loaf: action.loaf };
            }
            case 'BURGERS-TOGGLE-ADDITIONAL': {
                const additional = { ...state.additional };
                if (additional[action.additional]) {
                    delete additional[action.additional];
                } else if (action.additional === 'fries' && additional.halffries) {
                    delete additional.halffries;
                    additional[action.additional] = true;
                } else if (action.additional === 'batatyfries' && additional.halfbatatyfries) {
                    delete additional.halfbatatyfries;
                    additional[action.additional] = true;
                } else if (
                    action.additional === 'cheeseChivesFries' &&
                    additional.halfCheeseChivesFries
                ) {
                    delete additional.halfCheeseChivesFries;
                    additional[action.additional] = true;
                } else if (action.additional === 'halffries' && additional.fries) {
                    delete additional.fries;
                    additional[action.additional] = true;
                } else if (action.additional === 'halfbatatyfries' && additional.batatyfries) {
                    delete additional.batatyfries;
                    additional[action.additional] = true;
                } else if (
                    action.additional === 'halfCheeseChivesFries' &&
                    additional.cheeseChivesFries
                ) {
                    delete additional.cheeseChivesFries;
                    additional[action.additional] = true;
                } else if (action.additional === 'colazero' && additional.cola) {
                    delete additional.cola;
                    additional[action.additional] = true;
                } else if (action.additional === 'cola' && additional.colazero) {
                    delete additional.colazero;
                    additional[action.additional] = true;
                } else {
                    additional[action.additional] = true;
                }
                return { ...state, additional };
            }
            case 'SET-USER': {
                return { ...state, user: action.id };
            }
            case 'TAKE-USUAL': {
                return {
                    ...state,
                    burger: action.data.burger,
                    additional: action.data.additional,
                    ingredients: action.data.ingredients,
                    place: action.data.place,
                };
            }
            case 'UPDATE-UWAGI': {
                return {
                    ...state,
                    uwagi: action.uwagi,
                };
            }
            default:
                return state;
        }
    }
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { isAdmin } = props.user.value;
    const { myId } = props;
    const historyDownloaded = props.history !== undefined;
    const menuDownloaded = props.menu !== null;
    const [orderDone, setOrderDone] = React.useState(false);

    const day_date = new Date();
    const day = day_date.getDay();
    const przelicznik = day === 1 ? 0.9 : 1;

    React.useEffect(() => {
        setTimeout(() => emitToSocket('getMenu'), 500);
        setTimeout(() => {
            if (!menuDownloaded) {
                emitToSocket('getMenu');
            }
        }, 2500);
        setTimeout(() => {
            if (!menuDownloaded) {
                emitToSocket('getMenu');
            }
        }, 5000);
    }, [menuDownloaded]);
    React.useEffect(() => {
        setTimeout(() => emitToSocket('getBurgerHistory'), 500);
        setTimeout(() => {
            if (!historyDownloaded) {
                emitToSocket('getBurgerHistory');
            }
        }, 2500);
        setTimeout(() => {
            if (!historyDownloaded) {
                emitToSocket('getBurgerHistory');
            }
        }, 5000);
    }, [historyDownloaded]);

    React.useEffect(() => {
        const browser = Bowser.getParser(window.navigator.userAgent);
        if (browser.getBrowserName() !== 'Safari') {
            const permissions = Push.Permission.get();
            if (permissions === Push.Permission.DEFAULT) {
                Swal.fire({
                    icon: 'question',
                    title: 'Burger notifications',
                    text: 'Do you want to get notification while burgers are in the office?',
                    confirmButtonText: 'Yes, please',
                    showCancelButton: true,
                    cancelButtonText: 'Nope.',
                }).then((response) => {
                    if (response.value) {
                        Push.Permission.request(
                            () => {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Burger notifications',
                                    text: 'Good job 🎉',
                                });
                            },
                            () => {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Burger notifications',
                                    text: 'You make me sad. 😟',
                                });
                            },
                        );
                        Swal.fire({
                            icon: 'info',
                            title: 'Burger notifications',
                            text:
                                'You should now see notifications alert in your browser. To get notifications you should click Allow',
                            confirmButtonText: 'Okaay',
                        });
                    }
                });
            }
            setTimeout(() => {
                tokenManagment();
            }, 1000);
        }
    }, []);
    React.useEffect(() => {
        if (myId) {
            dispatch({
                type: 'SET-USER',
                id: myId,
            });
            if (theUsual[myId]) {
                dispatch({
                    type: 'TAKE-USUAL',
                    data: theUsual[myId],
                });
            }
        }
    }, [myId]);

    React.useEffect(() => {
        setTimeout(() => emitToSocket('getOrdersStatus'), 500);
        setTimeout(() => emitToSocket('getOrdersStatus'), 2500);
        setTimeout(() => emitToSocket('getOrdersStatus'), 5000);
    }, []);

    const todayOrders = React.useMemo(() => {
        let start = new Date();
        start.setHours(0, 0, 0, 0);
        start = start.getTime();
        let finish = new Date();
        finish.setHours(23, 59, 59, 59);
        finish = finish.getTime();
        if (!props.history) {
            return props.history;
        }
        return {
            burgers: props.history.burgers.filter((burger) => {
                return burger.dateStart > start && burger.dateStart < finish;
            }),
            fries: props.history.fries.filter((frie) => {
                return frie.dateStart > start && frie.dateStart < finish;
            }),
            batatyfries: props.history.batatyFries.filter((frie) => {
                return frie.dateStart > start && frie.dateStart < finish;
            }),
            cheeseChivesFries: props.history.cheeseChivesFries.filter((frie) => {
                return frie.dateStart > start && frie.dateStart < finish;
            }),
            cola: props.history.cola.filter((cola) => {
                return cola.dateStart > start && cola.dateStart < finish;
            }),
        };
    }, [props.history]);

    const price = React.useMemo(() => {
        if (!props.menu) {
            return 0;
        }
        let price = 0;
        if (props.menu.burgers && state.burger) {
            price += props.menu.burgers[state.burger].price;
        }
        price += Object.keys(state.ingredients).reduce((prev, current) => {
            return prev + props.menu.burgerIngredients[current].price;
        }, 0);
        price += Object.keys(state.additional).reduce((prev, current) => {
            if (!props.menu.additional[state.place][current]) {
                return prev;
            }
            return prev + props.menu.additional[state.place][current].price;
        }, 0);

        if (props.menu.burgers && state.burger) {
            const loafForBurger = props.menu.burgers[state.burger].ingredients.find((ing) =>
                ['regular-loaf', 'bulka_maslana', 'bulka_zytnia'].includes(ing),
            );
            if (
                loafForBurger !== state.loaf &&
                (state.loaf === 'bulka_zytnia' || state.loaf === 'bulka_maslana')
            ) {
                price += props.menu.burgerIngredients[state.loaf].price;
            }
        }
        return price;
    }, [props.menu, state]);

    if (
        props.menu === null ||
        !props.myId ||
        props.todayOrdersStatus === undefined ||
        !props.history
    ) {
        return <Loading />;
    }

    let todayOrdersElement = null;
    if (
        todayOrders.burgers.length > 0 ||
        todayOrders.fries.length > 0 ||
        todayOrders.batatyfries.length > 0 ||
        todayOrders.cheeseChivesFries.length > 0 ||
        todayOrders.cola.length > 0
    ) {
        todayOrdersElement = (
            <div className="todayOrders">
                <h2>Your today's order</h2>
                {todayOrders.burgers.length > 0 && (
                    <>
                        <h3>Burgers</h3>
                        <ul>
                            {todayOrders.burgers.map((item) => (
                                <li key={`burger-${item.id}`}>
                                    {getCrazyName(item)}
                                    {item.place === 'miejsce' && <strong> (w bro burgers)</strong>}
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                {todayOrders.fries.length > 0 && (
                    <>
                        <h3>Fries</h3>
                        <ul>
                            {todayOrders.fries.map((item) => (
                                <li key={`fries-${item.id}`}>
                                    {item.halffries ? 'Half fries' : 'Fries'}
                                    {item.place === 'miejsce' && <strong> (w bro burgers)</strong>}
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                {todayOrders.batatyfries.length > 0 && (
                    <>
                        <h3>Sweet potatoes fries</h3>
                        <ul>
                            {todayOrders.batatyfries.map((item) => (
                                <li key={`fries-${item.id}`}>
                                    {item.halffries
                                        ? 'Half sweet potatoesfries'
                                        : 'Sweet potatoes fries'}
                                    {item.place === 'miejsce' && <strong> (w bro burgers)</strong>}
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                {todayOrders.cheeseChivesFries.length > 0 && (
                    <>
                        <h3>Cheese, bacon, chives fries</h3>
                        <ul>
                            {todayOrders.cheeseChivesFries.map((item) => (
                                <li key={`fries-${item.id}`}>
                                    {item.halffries
                                        ? 'Half cheese, bacon, chives fries'
                                        : 'Cheese, bacon, chives fries'}
                                    {item.place === 'miejsce' && <strong> (w bro burgers)</strong>}
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                {todayOrders.cola.length > 0 && (
                    <>
                        <h3>Cola</h3>
                        <ul>
                            {todayOrders.cola.map((item) => (
                                <li key={`cola-${item.id}`}>
                                    {item.colazero ? 'Cola zero' : 'Cola'}{' '}
                                    <strong>(w bro burgers)</strong>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </div>
        );
    }
    if (orderDone) {
        return (
            <div className="orderBurger DONE">
                {todayOrdersElement}
                You order is complete. If you want to order something else, please enter this page
                again.
                <span />
            </div>
        );
    }
    if (props.todayOrdersStatus !== '1') {
        return (
            <div className="orderBurger NOTODAY">
                {todayOrdersElement}
                Ordering burgers is turned off.
                <span />
            </div>
        );
    }

    if (Object.keys(banned).indexOf(myId) > -1) {
        return (
            <div className="orderBurger BAN">
                {banned[myId]}
                <span />
            </div>
        );
    }
    const additionalFiltered1 = Object.keys(state.additional).filter((element) => {
        if (!props.menu.additional[state.place][element]) {
            return false;
        }
        return true;
    });
    const additionalFiltered = {};
    additionalFiltered1.forEach((element) => {
        additionalFiltered[element] = true;
    });
    return (
        <div className="orderBurger">
            {isAdmin && (
                <button
                    type="button"
                    onClick={() => {
                        dispatch({
                            type: 'TAKE-USUAL',
                            data: {
                                burger: null,
                                ingredients: {},
                                additional: {},
                                place: 'takeaway',
                            },
                        });
                    }}
                >
                    RESET
                </button>
            )}
            {isAdmin && (
                <div className="adminSection">
                    <p>Jestes adminem, wiec mozesz wybrac komu zamowic burgera:</p>
                    <ul>
                        {props.users
                            .filter((user) => user.value.active)
                            .map((user) => (
                                <li key={user.key}>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            dispatch({
                                                type: 'SET-USER',
                                                id: user.key,
                                            });
                                        }}
                                        className={state.user === user.key ? 'active' : ''}
                                    >
                                        <img src={user.value.avatarUrl} />
                                        <span>{user.value.displayName}</span>
                                    </button>
                                </li>
                            ))}
                    </ul>
                </div>
            )}
            {todayOrdersElement}
            <div className="order">
                <div className="builder">
                    <h2>Choose your burger</h2>
                    {takeAway.indexOf(props.myId) !== -1 && (
                        <>
                            <h3>Where do you want to eat?</h3>
                            <div className="place">
                                <button
                                    type="button"
                                    className={state.place === 'bro' ? 'eatbro active' : 'eatbro'}
                                    onClick={() =>
                                        dispatch({ type: 'BURGER-SET-PLACE', place: 'bro' })
                                    }
                                >
                                    <span>In bro burgers!</span>
                                </button>
                                <button
                                    type="button"
                                    className={
                                        state.place === 'takeaway'
                                            ? 'eattakeaway active'
                                            : 'eattakeaway'
                                    }
                                    onClick={() =>
                                        dispatch({ type: 'BURGER-SET-PLACE', place: 'takeaway' })
                                    }
                                >
                                    <span>Take away</span>
                                </button>
                            </div>
                        </>
                    )}
                    <h3>Burger</h3>
                    <ul className="chooseBurger">
                        {Object.keys(props.menu.burgers)
                            .filter((burger) => {
                                if (props.menu.burgers[burger].availableOnlyIn) {
                                    return (
                                        props.menu.burgers[burger].availableOnlyIn === state.place
                                    );
                                }
                                return true;
                            })
                            .map((burger) => (
                                <li key={burger}>
                                    <button
                                        type="button"
                                        className={state.burger === burger ? 'active' : ''}
                                        onClick={() => {
                                            if (burger === 'bluemike') {
                                                track('klikbluemike');
                                            } else if (
                                                burger === 'straznik' &&
                                                Math.random() < 0.2
                                            ) {
                                                track('klikstraznik');
                                            }
                                            dispatch({ type: 'BURGER-SET-BURGER', burger });
                                        }}
                                    >
                                        {getBurgerName(props.menu.burgers[burger])}
                                    </button>
                                </li>
                            ))}
                        <li className="noBurger">
                            <button
                                className={state.burger === false ? 'active' : ''}
                                type="button"
                                onClick={() => dispatch({ type: 'BURGER-NO-BURGER' })}
                            >
                                No burger
                            </button>
                        </li>
                    </ul>
                    {state.burger !== null && (
                        <>
                            {state.burger !== false && (
                                <>
                                    {state.burger !== 'weggington' &&
                                        state.burger !== 'ostry-kurczak-koreanski' &&
                                        state.burger !== 'juwe-cobb-burger' &&
                                        state.burger !== 'hempeat' && (
                                            <>
                                                <h3>Choose your main meat</h3>
                                                <ul className="chooseAdditionalIngredients">
                                                    {meats.map((meat) => (
                                                        <li key={meat}>
                                                            <button
                                                                className={
                                                                    state.meat === meat
                                                                        ? 'active'
                                                                        : ''
                                                                }
                                                                type="button"
                                                                onClick={() =>
                                                                    dispatch({
                                                                        type: 'BURGER-TOGGLE-MEAT',
                                                                        meat,
                                                                    })
                                                                }
                                                            >
                                                                {getMeatName(meat)}
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        )}

                                    <>
                                        <h3>Choose your loaf</h3>
                                        <ul className="chooseAdditionalIngredients">
                                            {loafs.map((loaf) => (
                                                <li key={loaf}>
                                                    <button
                                                        className={
                                                            state.loaf === loaf ? 'active' : ''
                                                        }
                                                        type="button"
                                                        onClick={() =>
                                                            dispatch({
                                                                type: 'BURGER-TOGGLE-LOAF',
                                                                loaf,
                                                            })
                                                        }
                                                    >
                                                        {getLoafName(loaf)}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </>

                                    <h3>Choose additional ingredients</h3>
                                    <ul className="chooseAdditionalIngredients">
                                        {Object.keys(props.menu.burgerIngredients)
                                            .filter(
                                                (ing) =>
                                                    ing !== 'bulka_zytnia' &&
                                                    ing !== 'bulka_maslana',
                                            )
                                            .map((ingredient) => (
                                                <li key={ingredient}>
                                                    <button
                                                        className={
                                                            state.ingredients[ingredient] === true
                                                                ? 'active'
                                                                : ''
                                                        }
                                                        type="button"
                                                        onClick={() =>
                                                            dispatch({
                                                                type: 'BURGER-TOGGLE-INGREDIENT',
                                                                ingredient,
                                                            })
                                                        }
                                                    >
                                                        {getIngredientName(
                                                            props.menu.burgerIngredients[
                                                                ingredient
                                                            ],
                                                        )}
                                                    </button>
                                                </li>
                                            ))}
                                    </ul>
                                </>
                            )}
                            <h3>Do you want something not burger related?</h3>
                            <ul className="chooseAdditionals">
                                {Object.keys(props.menu.additional[state.place]).map(
                                    (additional) => (
                                        <li key={additional}>
                                            <button
                                                className={
                                                    state.additional[additional] === true
                                                        ? 'active'
                                                        : ''
                                                }
                                                type="button"
                                                onClick={() =>
                                                    dispatch({
                                                        type: 'BURGERS-TOGGLE-ADDITIONAL',
                                                        additional,
                                                    })
                                                }
                                            >
                                                {getAdditionalName(
                                                    props.menu.additional[state.place][additional],
                                                )}
                                            </button>
                                        </li>
                                    ),
                                )}
                            </ul>
                            <h3>Anything you need to say?</h3>
                            <textarea
                                className="uwagi"
                                value={state.uwagi}
                                onChange={(event) =>
                                    dispatch({
                                        type: 'UPDATE-UWAGI',
                                        uwagi: event.target.value,
                                    })
                                }
                            />
                        </>
                    )}
                </div>
                <div className="summary">
                    <h2>Your order summary</h2>
                    {!state.burger && Object.keys(state.additional).length === 0 && (
                        <p>Nothing chosen, yet :(</p>
                    )}
                    {(state.burger || Object.keys(state.additional).length > 0) && (
                        <>
                            {state.burger && (
                                <>
                                    <p>
                                        Chosen burger:{' '}
                                        <strong>
                                            {getBurgerName(props.menu.burgers[state.burger])}
                                        </strong>
                                    </p>
                                    <h3>Current ingredients</h3>
                                    <ul className="currentIngredientsList">
                                        {props.menu.burgers[state.burger].ingredients.map(
                                            (ingredient) => {
                                                if (
                                                    ingredient === 'beef' &&
                                                    state.meat !== 'normal'
                                                ) {
                                                    return (
                                                        <li key={ingredient}>
                                                            🔄{getMeatName(state.meat)}
                                                        </li>
                                                    );
                                                }
                                                if (
                                                    ingredient === 'regular-loaf' ||
                                                    ingredient === 'bulka_maslana' ||
                                                    ingredient === 'bulka_zytnia'
                                                ) {
                                                    return (
                                                        <li key={ingredient}>
                                                            {state.loaf !== ingredient && '🔄'}
                                                            {getLoafName(state.loaf)}
                                                        </li>
                                                    );
                                                }
                                                return (
                                                    <li key={ingredient}>
                                                        {getIngredientName({
                                                            name: ingredient,
                                                        })}
                                                    </li>
                                                );
                                            },
                                        )}
                                        {Object.keys(state.ingredients).map((ingredient) => (
                                            <li key={`additional${ingredient}`}>
                                                ➕
                                                {getIngredientName({
                                                    name: ingredient,
                                                })}
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {Object.keys(additionalFiltered).length > 0 && (
                                <>
                                    <h3>Additional stuff</h3>
                                    <ul className="additionalStuff">
                                        {Object.keys(additionalFiltered).map((additional) => (
                                            <li key={additional}>
                                                {getAdditionalName({ name: additional })}
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {takeAway.indexOf(props.myId) !== -1 && (
                                <p>
                                    <strong>
                                        You chose to eat{' '}
                                        {state.place === 'bro' ? 'in Bro burgers' : 'in Tidio'}
                                    </strong>
                                </p>
                            )}
                            <p>
                                Your current price:{' '}
                                <strong>{(price * przelicznik).toFixed(2)}</strong> zł
                            </p>
                        </>
                    )}
                </div>
            </div>
            <button
                className="orderBurgerButton"
                type="button"
                disabled={price === 0}
                onClick={() => {
                    Swal.showLoading();
                    console.log(state, additionalFiltered);
                    const data = {
                        ...state,
                        additional: additionalFiltered,
                        ingredients: { ...state.ingredients },
                    };
                    const loafForBurger = props.menu.burgers[state.burger].ingredients.find((ing) =>
                        ['regular-loaf', 'bulka_maslana', 'bulka_zytnia'].includes(ing),
                    );
                    if (state.loaf !== loafForBurger && state.loaf === 'regular-loaf') {
                        data.loaf = 'bulka_regular';
                    } else if (state.loaf === loafForBurger) {
                        delete data.loaf;
                    }
                    emitToSocket('setFullOrder', data);
                    if (state.burger === 'bluemike') {
                        track('orderbluemike');
                    } else {
                        track('orderburger');
                    }
                    dispatch({
                        type: 'TAKE-USUAL',
                        data: {
                            burger: null,
                            ingredients: {},
                            additional: {},
                            place: 'takeaway',
                            uwagi: '',
                            dateStart: new Date().getTime(),
                            meat: 'normal',
                            loaf: 'regular-loaf',
                        },
                    });
                    setOrderDone(true);
                }}
            >
                Order now!
            </button>
        </div>
    );
};
const enhance = compose(
    firebaseConnect(['users']),
    connect((state) => ({
        users: state.firebase.ordered.users,
        auth: state.firebase.auth,
        user:
            state.firebase.ordered.users &&
            getUser(state.firebase.ordered.users, state.firebase.auth.uid),
        menu: getMenu(state),
        myId: getMyId(state),
        todayOrdersStatus: getTodayOrdersStatus(state),
        history: getBurgerHistory(state),
    })),
);

export default enhance(Burgers);
